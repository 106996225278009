import Component from '../../../../../libs/components/component';
import { getUserService, userManager } from '../../../../../libs/user-service';
import ShareLinks from '../st26-sharelink/script';
import './style.scss';

export default class Actions extends Component {

    #DATA_ID_SAVE = 'data-id-save';

    constructor(name, root) {
        super(name, root);

        this.shareBtn = this._dElMod('btn', 'share');
        this.saveBtn = this._dElMod('btn', 'save');
        this.shareId = null;

        this.url = this.root.dataset.url;
        this.title = this.root.dataset.title;
        if (!this.url.startsWith('http')) this.url = window.location.origin + this.url;
        this.path = this.root.dataset.path;

        this._addEventListeners();
        this._init();
    }

    async _init() {
        // create share
        this.shareId = await ShareLinks.generateShareLinks(this.url, this.title);

        // setup mutation observer for save button (before searching recipe in fav)
        this._initSaveBtnMutationObserver();

        // favorites recipes
        await userManager.searchRecipeInFav(this.saveBtn, this.path);
    }

    _initSaveBtnMutationObserver() {
        this.destroySaveBtnMutationObserver = this._createSaveBtnMutationObserver();
    }

    _createSaveBtnMutationObserver() {
        const callback = /** @type {MutationCallback} */((mutations, _observer) => {
            for (const m of mutations) {
                const target = /** @type {HTMLElement} */(m.target);
                const icon = target?.querySelector('span[class^="ec-icon-"]');
                if (m.type === 'attributes' && m.attributeName === this.#DATA_ID_SAVE) {
                    icon?.classList.remove('ec-icon-bookmark-off', 'ec-icon-bookmark-on');
                    const isAttributeAdded = m.oldValue === null && target.hasAttribute(m.attributeName);
                    const isAttributeRemoved = m.oldValue !== null && !target.hasAttribute(m.attributeName);
                    if (isAttributeAdded) {
                        icon?.classList.add('ec-icon-bookmark-on');
                    } else if (isAttributeRemoved) {
                        icon?.classList.add('ec-icon-bookmark-off');
                    }
                }
            }
        });
        const observer = new MutationObserver(callback);
        const observerConfig = /** @type {MutationObserverInit} */({
            attributes: true,
            attributeFilter: [this.#DATA_ID_SAVE],
            attributeOldValue: true,
        });
        observer.observe(this.saveBtn, observerConfig);
        // return a function to disconnect the observer manually if needed
        return () => observer.disconnect();
    }

    _addEventListeners() {
        this.shareBtn.addEventListener('click', (ev) => {
            if (this.shareId) {
                const event = new CustomEvent('showShareLinks', { bubbles: true });
                event.data = {
                    root: this.shareBtn,
                };
                document.getElementById(this.shareId).dispatchEvent(event);
                ev.stopPropagation();
            }
        });

        this.saveBtn.addEventListener('click', () => {
            const userService = getUserService();
            userService.clickFavoriteRecipe(this.saveBtn, this.path, this.saveBtn.dataset.idSave);
        });
    }
}
